import {DropdownItem, FormControl, showNotification} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';

import {
  GetContextTemplateListApiResponse,
  useRenderCustomerEmailTemplateMutation,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';

import {VARIABLE_REGEX} from '../../../utils/getVariables';
import {handleApiError} from '../../../utils/handleApiError';
import {SendEmailFormValues} from '../types/SendEmailFormValues';

type Props = {
  formApi: UseFormReturn<SendEmailFormValues>;
  control: FormControl<SendEmailFormValues>;
  customerId?: string | null;
  renderTemplate: ReturnType<typeof useRenderCustomerEmailTemplateMutation>[0];
  templates: GetContextTemplateListApiResponse | undefined;
};

export const TemplatesEmailDropdownContent = (props: Props) => {
  const onTemplateClick = async (templateId: string) => {
    const response = await props
      .renderTemplate({
        templateId,
        customerContextTemplateRequestBody: {
          customerId: props.customerId ?? '',
        },
      })
      .unwrap()
      .catch(handleApiError);
    if (!response) {
      return;
    }
    props.formApi.setValue(
      'subject',
      response.subject.replaceAll(new RegExp(VARIABLE_REGEX, 'g'), '____')
    );
    props.formApi.setValue(
      'body',
      response.body.replaceAll(new RegExp(VARIABLE_REGEX, 'g'), '____')
    );

    if (VARIABLE_REGEX.test(response.subject) || VARIABLE_REGEX.test(response.body)) {
      showNotification.warning(i18n.t('general.notifications.notAllPlaceholdersFilled'));
    }
  };

  return (
    <>
      {props.templates?.map((template) => (
        <DropdownItem
          label={template.name}
          key={template.id}
          onClick={() => onTemplateClick(template.id)}
        />
      ))}
    </>
  );
};
